





import { Component, Vue,  } from 'vue-property-decorator'
import { namespace, Action } from 'vuex-class'
import { Route } from 'vue-router'

const auth = namespace('auth')
@Component
export default class extends Vue {

	@auth.Action
	public logout!: () => void

	async mounted() {
		await this.logout()
		this.$router.push('/auth/superadmin/login')
	}

	// beforeRouteEnter (to: Route, from: Route, next: any) {
	// 	next((vm: any) => {
	// 		vm.logout().then((res: any) => {
	// 			let params: any = {name: 'login'}
	// 			if (from.name) {
	// 				params['query'] = {ref: from.name}
	// 			}
	// 			vm.$router.replace(params)
	// 		})
	// 	})
	// }
}
